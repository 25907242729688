.totalAgentData {
  border: 1px solid red;
  width: 100vh;
}

.formInline {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  margin: 2px;
}

.formInline .buttongroup {
  display: flex;
  justify-content: center;
  margin-top: 0.7rem;
  margin-left: 1rem;
}
